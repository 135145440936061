<template>
  <div class="main_profile_wrapper">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->

    <!-- Table And Data -->
    <div class="main_table">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal"
        title="In School Tasks"
        text="Manage and edit your tasks or add new ones."
        buttonText="Add new task"
        :total="3"
      ></TableHeaderSection>
      <!-- Start Main Table Here  padding -->
      <div class="header_container just_custom_table_tasks">
        <CustomTable
          class=""
          :count="$store.getters['user/tasks_count']"
          :DataItems="$store.getters['user/tasks']"
          @Refresh="Refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          hidden_export_import
          admin_permission="admin_tasks"
          edit_permission="add_tasks"
          @selection="selections"
        >
          <!-- search & filter fields  -->
          <template #filter>
            <el-select
              v-model="filter.topic_id"
              clearable
              placeholder="Topic - All"
              class="select_filter_custom"
            >
              <el-option
                v-for="(option, index) in $store.getters['topics/topics']"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template>

          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="80"></el-table-column>

            <!-- Task Name  -->
            <el-table-column
              prop="name"
              sortable
              :label="`${localization('Name')}`"
            ></el-table-column>
            <!-- Task Topic -->
            <el-table-column
              prop="topic_name"
              sortable
              :label="`${localization('Topic')}`"
              class="d-none d-md-block"
            ></el-table-column>

            <!-- //? Task Actions  -->
            <el-table-column
              prop=""
              label="Selected Strategies"
              min-width="100"
              width="auto"
            >
              <template slot-scope="scope">
                <div class="main_custom_parts_pattern">
                  <div class="part_pattern s">
                    <span>S</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Sequence
                        ? scope.row.selected_strategies_count.Sequence
                        : 0
                    }}</span>
                  </div>
                  <!-- .selected_strategies_count?.Sequence -->
                  <div class="part_pattern p">
                    <span>P</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Precision
                        ? scope.row.selected_strategies_count.Precision
                        : 0
                    }}</span>
                  </div>
                  <div class="part_pattern t">
                    <span>T</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count["Technical Reasoning"]
                        ? scope.row.selected_strategies_count[
                            "Technical Reasoning"
                          ]
                        : 0
                    }}</span>
                  </div>
                  <div class="part_pattern c">
                    <span>C</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Confluence
                        ? scope.row.selected_strategies_count.Confluence
                        : 0
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <!-- //? Task Actions  -->
            <el-table-column prop="" label="">
              <template slot-scope="scope">
                <div class="td_actions flex justify-content-end">
                  <!-- show task button  -->
                  <router-link
                    v-if="hasPermission('show_tasks')"
                    :to="`/new-theme/tasks/${scope.row.id}`"
                    v-tooltip.top-center="
                      `${localization('Show personal strategy')}`
                    "
                    class="btn btn-info btn-link btn-xs"
                  >
                    <img
                      src="@/assets/new-theme/eye-inactive.png"
                      alt="eye-inactive"
                    />
                  </router-link>
                  <!-- Edit Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Edit personal strategy')}`
                    "
                    class="btn btn-warning btn-simple btn-link"
                  >
                    <img
                      src="@/assets/new-theme/edit-05-inactive.png"
                      alt="edit-05-inactive"
                    />
                  </a>
                  <span class="btn btn-warning btn-simple btn-link">
                    <img
                      src="@/assets/new-theme/printer-inactive.png"
                      alt="printer-inactive"
                    />
                  </span>
                </div>
              </template>
            </el-table-column>
          </template>
        </CustomTable>
        <!-- <div class="show_print_or_delete_items">
          hello
        </div> -->
      </div>
      <!-- End Main Table Here -->
    </div>
    <!-- End Table And Data -->

    <!-- Start Add Task Modal -->
    <div>
      <!-- Task Modal -->
      <el-dialog
        title="Add a new task"
        :visible.sync="dialogVisible"
        width="600px"
        custom-class="task-modal"
        @close="resetForm"
      >
        <!-- Task Type Selection -->
        <div class="task-type">
          <el-radio-group
            v-model="task.type"
            size="medium"
            class="custom-radio-group"
          >
            <el-radio :label="taskTypes[0]" class="custom-radio"
              >Study-related task</el-radio
            >
            <el-radio :label="taskTypes[1]" class="custom-radio"
              >Work-related task</el-radio
            >
          </el-radio-group>
        </div>

        <!-- Task Form -->
        <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
          <!-- Task Name -->
          <el-form-item prop="name">
            <AppInputLabel text="Name your task" :star="true"></AppInputLabel>
            <el-input
              class="new_theme_custom_input"
              v-model="task.name"
              placeholder="Give you task a name"
              clearable
            ></el-input>
          </el-form-item>

          <!-- Task Topic -->
          <el-form-item prop="topic">
            <AppInputLabel text="Task category" :star="true"></AppInputLabel>
            <el-input
              class="new_theme_custom_input"
              v-model="task.topic"
              placeholder="Enter task topic"
              clearable
            ></el-input>
          </el-form-item>

          <!-- Task Description -->
          <el-form-item prop="description">
            <AppInputLabel text="Task description" :star="true"></AppInputLabel>
            <el-input
              class="new_theme_custom_input input_with_custom_text_area"
              type="textarea"
              v-model="task.description"
              placeholder="Add task description"
              clearable
            />
          </el-form-item>
        </el-form>

        <!-- File Upload Section -->
        <div class="file-upload">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-success="handleUploadSuccess"
            :on-progress="handleProgress"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :auto-upload="false"
            :on-remove="handleRemove"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Click to upload or drag and drop</div>
            <div class="el-upload__tip">
              Supported: PNG, JPG, or PDF (Max 5MB)
            </div>
          </el-upload>
        </div>

        <!-- Upload Progress Bar -->
        <el-progress
          v-if="uploadPercentage > 0"
          :percentage="uploadPercentage"
          type="line"
          status="success"
        ></el-progress>

        <!-- Display Uploaded Files with Delete Option -->
        <div v-if="fileList.length > 0" class="uploaded-files">
          <h4>Uploaded Files:</h4>
          <ul>
            <li v-for="file in fileList" :key="file.name" class="file-item">
              <i class="el-icon-document"></i> {{ file.name }} ({{
                (file.size / 1024).toFixed(2)
              }}
              KB)
              <i
                class="el-icon-delete file-delete"
                @click="removeFile(file)"
              ></i>
            </li>
          </ul>
        </div>

        <!-- Footer Buttons -->

        <div class="lci_modal_buttons mt-4">
          <button @click="dialogVisible = false" class="lci_btn_modal_cancel ">
            Cancel
          </button>
          <button
            @click="submitForm"
            class="lci_btn save  lci_btn_modal_cancel"
          >
            Confirm
          </button>
        </div>
      </el-dialog>
    </div>
    <!-- End Add Task Modal -->
  </div>
</template>

<script>
export default {
  name: "TasksView",

  data() {
    return {
      showAddTask: false,
      dialogVisible: false,
      taskTypes: ["Study-related task", "Work-related task"],
      task: {
        type: "Study-related task",
        name: "",
        topic: "",
        description: ""
      },
      fileList: [],
      uploadPercentage: 0, // Upload progress percentage
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      },
      filter: {}
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    }
  },
  methods: {
    // Get User Data And Set User Score
    // Use First:35 - 25
    // As Needed:24 - 18
    // Avoid:17 - 7
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    ToggleTaskModal() {
      this.showAddTask = !this.showAddTask;
      this.dialogVisible = !this.dialogVisible;
    },
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.mainUserId, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
      this.$store.dispatch("user/handlerHowToUseYourPatterns");
      this.$store.dispatch("topics/handlerStandardTopics");
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      console.log(this.selected);
      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
    // ! test
    handleUploadSuccess(response, file, fileList) {
      this.fileList = fileList;
      this.uploadPercentage = 0; // Reset progress after upload
    },
    handleProgress(event, file, fileList) {
      this.uploadPercentage = Math.round((event.loaded / event.total) * 100);
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("File size cannot exceed 5MB.");
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    removeFile(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$message.success("Form is valid. Strategies will be generated.");
          this.dialogVisible = false;
          // Implement strategy generation logic
          console.log("Task created:", this.task);
          console.log("Uploaded Files:", this.fileList);
        } else {
          this.$message.error("Please fill out all required fields.");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.fileList = [];
      this.uploadPercentage = 0;
    }
  },
  mounted() {
    this.getMainUserData();
    this.Refresh();
  }
};
</script>

<style lang="scss" scoped>
.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  .lci_btn_modal_cancel {
    background-color: #fff;
    color: #667085;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #eaecf0;
    width: calc(97% / 2);
    &.save {
      background-color: #7f56d9;
      color: #fff;
    }
  }
}
.main_custom_parts_pattern {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  .part_pattern {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 16px;
    height: 25px;
    padding: 10px 13px;
    width: 55px;
    font-weight: 500;
    &.s {
      color: #39c;
      border-color: #39c;
    }
    &.p {
      color: #3c3;
      border-color: #3c3;
    }
    &.t {
      color: #f93;
      border-color: #f93;
    }
    &.c {
      color: #c36;
      border-color: #c36;
    }
  }
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.padding {
  padding: 14px;
}
.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;

  .head_table {
    border-bottom: 1px solid #eaecf0;
  }
}
</style>

<style scoped>
.task-modal {
  padding: 20px;
}
.task-type {
  margin-bottom: 20px;
}
.task-form {
  margin-bottom: 20px;
}
.file-upload {
  margin-bottom: 20px;
  text-align: center;
}
.uploaded-files {
  margin: 20px 0;
}
.uploaded-files h4 {
  margin-bottom: 10px;
}
.uploaded-files ul {
  list-style: none;
  padding: 0;
}
.uploaded-files li {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-files li .el-icon-document {
  margin-right: 10px;
}
.file-delete {
  cursor: pointer;
  color: red;
  margin-left: 10px;
  font-size: 18px;
}
.el-upload {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 5px;
}
.el-upload__text {
  color: #409eff;
  font-size: 16px;
}
.el-upload__tip {
  color: #909399;
}
.dialog-footer {
  text-align: right;
}
.el-button {
  margin-left: 10px;
}
.el-progress {
  margin: 20px 0;
}

/* Custom Radio Buttons */
.custom-radio-group {
  display: flex;
  justify-content: space-between;
}

.custom-radio {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.custom-radio.is-checked {
  border-color: #67c23a;
  background-color: rgba(103, 194, 58, 0.1);
}
</style>
